<template>
  <div>
    <div class="column is-full">
      <div class="is-flex justify-flex-end">
        <b-button
          :to="{ name: 'VacancyCandidates', params: { id: data.vacancy._id }, query: query }"
          icon-left="arrow-left"
          rounded
          tag="router-link"
          type="is-primary"
        >
          Regresar
        </b-button>
      </div>
    </div>
    
    <!-- Banner de candidato -->
    <div class="column is-full mt-2 candidate-header">
      <!-- Nombre y botón descargar -->
      <div class="is-flex is-justify-content-space-between">
        <h1 class="title has-text-primary mb-2 is-size-3">
          {{ data.profile.firstName }} {{ data.profile.firstSurname }} {{ data.profile.lastSurname }}
        </h1>
        <div>
          <b-button
            @click="$refs.downloadProfile.open()"
            type="is-primary"
            size="is-small"
            class="has-text-weight-semibold"
          >
            Descargar perfil en PDF
          </b-button>
        </div>
      </div>

      <!-- Tags de aplicación -->
      <section>
        <b-tag
          rounded
          type="is-primary is-light"
        >
          {{ statuses[data.status] }}
        </b-tag>
        <b-tag
          class="ml-2"
          rounded
          type="is-info is-light"
        >
        {{ data.createdAt | formatDate }}
        </b-tag>
      </section>
    </div>

    <!-- Banner de vacante y aplicacion -->
    <div class="column is-full py-0 mt-4">
      <h2 class="title is-4 has-text-blue m-0">
        Vacante: {{ data.vacancy.name }}
      </h2>
      <h2 class="title m-0">
        <b-tag
          rounded
          type="is-info is-light"
        >
          Reclutamiento: {{ data.typeCustomRecruitment ?? "Normal" }}
        </b-tag>
        <b-tag
          class="ml-2"
          rounded
          type="is-primary is-light"
        >
          {{ statuses[data.status] }}
        </b-tag>
      </h2>
    </div>

    <!-- Descarga de perfil -->
    <div class="columns column is-full" :style="{marginTop:'1rem'}">
      <div class="is-flex is-justify-content-space-between" :style="{width:'65%'}">
        <b-button
          @click="$refs.downloadProfile.open()"
          type="is-primary"
        >
          Descargar en PDF
        </b-button>
<!--         Boton de Historial (Logs) -->
          <b-button
            type="is-primary"
            icon-left="clock-rotate-left"
            v-if="canSeeLogs"
            @click="openLogsModal">
            Ver historial de cambios
        </b-button>
      </div>

      <DownloadCandidateProfile
        :application="data"
        ref="downloadProfile"
      />
    </div>
    <LogsModal v-if="toggleLogs" :isModalActive="toggleLogs" @close-modal="closeModal" :profileLogsData="profileLogsData" :id="data._id"/>
  </div>
</template>

<script>
  import DownloadCandidateProfile from "@/components/modals/DownloadCandidateProfile";
  import { allowedViewerToCandidateLogs } from '../../lib/permissions';
  import LogsModal from './LogsModal.vue';
  export default {
    props:{
      data: {
        type: Object,
        required: true,
      },
    },
    components: {
      DownloadCandidateProfile,
      LogsModal,
    },
    data() {
      return {
        statuses: {
          applying: "En aplicación",
          selected: "Seleccionad@",
          sent: "En revisión con la empresa",
          rejected: "Rechazad@",
          "pre-hired": "Seleccionad@ por la empresa",
          hired: "Contratad@",
        },
        toggleLogs : false, //Boton para ver historial de cambio
        profileLogsData : {}
      };
    },
    computed: {
      query() {
        return this.$route.query;
      },
      canSeeLogs () {
        return allowedViewerToCandidateLogs.includes(this.$isRole())
      }
  },
  methods: {
    openLogsModal(){      
      this.profileLogsData = {
        name: this.data?.candidate || "",
      }
      this.toggleLogs = true
    },
    closeModal(){
      this.toggleLogs = false
    }
  }
    
  };
</script>

<style scoped lang="scss">
.candidate-header {
  background-color: white;
  padding: 1rem;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
}

</style>