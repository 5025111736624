export default {
  generalData: {
    newVacancy: "Nueva Vacante",
    title: "Información Principal",
    returnButton: "Regresar",
    vacancyAction: "Editar",
    fields: {
      alignamentDate: {
        label: "Fecha de alineacion",
        select: "Selecciona una fecha"
      },
      name: {
        name: 'name',
        label: "Nombre de la vacante",
        validation: "required",
      },
      duration: {
        name: 'duration',
        label: "Duración",
        validation: "required",
      },
      places: {
        name: 'places',
        label: "Número de Posiciones",
        validation: "required|numeric|min_value:1",
      },
      state: {
        name: 'state',
        label: "Estado",
        validation: "required",
      },
      city: {
        name: 'city',
        label: "Ciudad",
        validation: "required",
      },
      showScholarship: {
        name: 'showScholarship',
        label: "Mostrar beca",
        validation: "required",
      },
      scholarship: {
        name: 'scholarship',
        label: "Beca",
        validation: "required|numeric",
      },
      schedule: {
        name: 'schedule',
        label: "Horario",
        validation: "required",
        tooltip: "6 horas diarias, 30 horas semanales, Matutino, 8 a.m. a 2 p.m."
      },
      modality: {
        name: 'modality',
        label: "Modalidad",
        validation: "required",
      },
    },
  },
  academicData: {
    title: "Formación Académica",
    fields: {
      degree: {
        name: 'degree',
        label: "Licenciatura(s)",
        validation: "required",
        placeholder: "Presione 'Enter' para agregar"
      },
      academicLevel: {
        name: 'academicLevel',
        label: "Nivel académico",
        validation: "required",
        options: {
          student: "Estudiante",
          graduated: "Graduado",
          experienced: "Graduado con experiencia"
        }
      },
      minimumSemester: {
        name: 'minimumSemester',
        label: "Semestre Mínimo",
        validation: "required",
      },
      maximumSemester: {
        name: 'maximumSemester',
        label: "Semestre Máximo",
        validation: "required",
      },
      excelLevel: {
        name: 'excelLevel',
        label: "Nivel de Excel",
        validation: "required",
      },
      graduateDate: {
        name: 'graduateDate',
        label: "Término de Materias",
        validation: "required",
        tooltip: "Maximo mes de termino de materias permitido"
      },
      degreeDate: {
        name: 'degreeDate',
        label: "Titulación",
        validation: "required",
        tooltip: "Mes máximo para obtención de Título"
      },
    },
  },
  languageData: {
    title: "Idiomas de la Vacante",
    fields: {
      englishLevels: {
        label: "Inglés",
        proficiency: {
          desirable: "Deseable",
          basic: "Básico",
          intermediate: "Intermedio",
          advanced: "Avanzado",
          representative: "Representativa",
          writing: "Escritura",
          reading: "Lectura",
          listening: "Escucha",
          speaking: "Conversación",
        },
      },
      otherLanguages: {
        label: "Otros Idiomas",
        proficiency: {
          desirable: "Deseable",
          basic: "Básico",
          intermediate: "Intermedio",
          advanced: "Avanzado",
          representative: "Representativa",
          writing: "Escritura",
          reading: "Lectura",
          listening: "Escucha",
          speaking: "Conversación",
        },
      },
      addLanguageButton: {
        label: "Agregar idioma",
      },
    },
  },
  habilities: {
    title: "Habilidades y Conocimientos",
    fields: {
      skills: {
        label: "Habilidades",
        info:
          "Habilidades, competencias y características personales necesarias para el puesto.",
        addSkillButton: {
          label: "Agregar conocimiento",
        }
      },
      knowledges: {
        label: "Conocimientos",
        info:
          "Conocimientos como programas, herramientas, equipos, etc., necesarios para el puesto.",
        level: {
          label: "Nivel",
        },
        addKnowledgeButton: {
          label: "Agregar conocimiento",
        },
        infoLimit: "Límite máximo de conocimientos alcanzado"
      },
    },
  },
  description: {
    title: "Descripción de la Práctica",
    fields: {
      goal: {
        name: 'goal',
        label: "Objetivo",
        validation: "required",
      },
      benefit: {
        name: 'benefit',
        label: "Beneficios",
        validation: "required",
        addBenefitButton: {
          label: "Agregar beneficio",
        },
      },
      activities: {
        name: 'activities',
        label: "Actividades Diarias",
        validation: "required",
        addActivityButton: {
          label: "Agregar actividad",
        },
      },
    },
  },
  solicitants: {
    title: "Datos de Contacto",
    availableAuthors: "Contactos adicionales",
    fields: {
      author: {
        name: 'author',
        label: "Contacto seleccionado",
        unassigned: "Sin Asignar"
      },
      additionalSolicitants: {
        label: "Agregar otros contactos",
        tooltip: {
          label: "Desmarcar esta opción elimina los contactos adicionales añadidos",
        }
      },
      availableContacts: {
        label: "Contactos disponibles",
        add: "Agregar"
      },
      additionalContacts: {
        availableLabel: "Contactos adicionales disponibles",
        assignedLabel: "Contactos adicionales asignados",
        info: "Asigna por lo menos 1 contacto adicional"
      }
    },
    buttonLabels: {
      addUser: "Agregar Usuario",
      selectAction: " Seleccionar"
    },
    privacyDisclaimer: "Estos datos son confidenciales y de uso exclusivo de Pro Meritum.",
    modal: {
      title: 'Crear Usuario',
      name: "Nombre (s)",
      maternalSurname: "Apelido Materno",
      paternalSurname: "Apellido Paterno",
      email: 'Correo electrónico',
      recruiter: "Reclutador",
      calendlyUrl: "Calendly URL",
      company: "Empresa",
      buttons: {
        cancel: "Cancelar",
        save: "Guardar"
      }
    }
  },
  recruitment: {
    title: "Acerca del Reclutamiento",
    fields: {
      requisitionScheme: {
        label: "Esquema de requisición",
      },
      serviceType: {
        label: "Tipo de Servicio",
      },
      claimType: {
        label: "Tipo de reclamación",
      },
      serviceDetail: {
        label: "Detalle de Servicio"
      }
    },
  },
  project: {
    title: "Proyecto",
    fields: {
      killerQuestions: {
        label: "Killer Questions",
        desc: "Preguntas que ayudarán a identificar a los candidatos ideales para la posición.",
        buttonAction: "Agregar Pregunta",
        answerType: "Tipo de Respuesta",
        option: "Opción",
        multiple: "Opciones"
      },
      comments: {
        title: "Comentarios",
        label: "Comentarios",
        placeholder: "Comentarios, observaciones, aclaraciones y más...",
      },
      additionalFields: {
        label: "Campos Adicionales",
      },
      uploadFile: {
        placeholder: "Formatos soportados: .pdf, .docx, .cvs (Max: 5Mb).",
        label: "Subir archivo",
        viewFile: "Ver archivo",
        successFile: "El archivo se subió con éxito"
      }
    },
  },
  languageInfo: {
    title: "Idioma de la vacante"
  },
  vacancyFormLanguageOptions: {
    spanish: "Español",
    english: "Inglés"
  },
  saveData: {
    disclaimer: "Revisa adecuadamente los campos antes de guardar los cambios.",
    descMonday: "Escribe manualmente el ID de requisición de Monday",
    idMonday: "ID de requisición",
    projects: {
      title: "Proyecto",
      lable: "Selecciona un proyecto",
      disclaimer: "No hay proyectos disponibles"
    },
    saveButton: {
      label: "Guardar",
      create: "Crear Vacante",
      update: "Actualizar Vacante",
      error: "Uno o más campos están incompletos"
    }
  },
  additionalFields: {
    label: "Campos adicionales",
    info: "No se encontraron campos adicionales"
  }
};
